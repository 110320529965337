import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"space-around"}},[_c('p',[_vm._v("This Page will Host the SureScripts Interface")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"9","sm":"4"}},[_c(VCard,[_c(VCardText,[_c('patient-select',{on:{"patient-select":_vm.updatePatient}}),_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Add Patient ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VCard,[_c(VCardText,[_c(VBtn,{staticClass:"mb-3",attrs:{"color":"success","block":""}},[_c(VIcon,{staticClass:"me-2",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiSendOutline)+" ")]),_c('span',[_vm._v("Submit Request")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mb-3",attrs:{"color":"secondary","block":""}},[_c(VIcon,{staticClass:"me-2",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudPrintOutline)+" ")]),_c('span',[_vm._v("Print Request")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }