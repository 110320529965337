<template>
  <div>
    <v-row
      justify="space-around"
    >
      <p>This Page will Host the SureScripts Interface</p>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="9"
        sm="4"
      >
        <v-card>
          <v-card-text>
            <patient-select
              @patient-select="updatePatient"
            ></patient-select>
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Add Patient
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Right Column: Action -->
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-card-text>
            <v-btn
              color="success"
              class="mb-3"
              block
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiSendOutline }}
              </v-icon>
              <span>Submit Request</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="mb-3"
              color="secondary"
              block
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiCloudPrintOutline }}
              </v-icon>
              <span>Print Request</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PatientSelect from '@/components/patient/PatientSelect.vue'
import { mdiCloudPrintOutline, mdiSendOutline } from '@mdi/js'

export default {
  components: {
    PatientSelect,
  },

  setup() {
    return {
      icons: {
        mdiSendOutline,
        mdiCloudPrintOutline,
      },
    }
  },
  methods: {
    log(msg) {
      //console.log(msg)
    },
    updatePatient(patientDTO) {
      this.log(patientDTO)
      this.patientDTO = patientDTO.value
    },
  },
}
</script>
